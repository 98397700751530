.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
}
body {
  font-size: 1.2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: url("./imgs/background-03.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.centerContainer {
  background-color: whitesmoke;
  padding: 20px 40px;
  min-width: 40%;
  max-width: 40%;
  border-radius: 4px;
  min-height: 40%;
  max-height: 40%;
  position: relative;
}

h1 {
  font-size: 26px;
}
.selected {
  background-color: lightgrey;
}
.options {
  border-bottom: 1px solid gray;
}

ul {
  list-style-type: none;
}

.results {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.button {
  border: 1px solid #2e86c1;
  background-color: #2e86c1;
  color: white;
  padding: 10px 50px;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  right: 40px;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  /* width: 80%; Could be more or less, depending on screen size */
  max-width: 40%;
  min-height: 33%;

  position: relative;
}

.modal-content .button {
  right: 50%;
  transform: translateX(50%);
}

@media only screen and (max-width: 940px) {
  .centerContainer {
    max-height: 60%;
    min-height: 60%;
  }
}
